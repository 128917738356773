import React from "react";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import ReactTooltip from "react-tooltip";

import ReactCountryFlag from "react-country-flag";
import { IoMapSharp } from "@react-icons/all-files/io5/IoMapSharp";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { AiOutlineUnorderedList } from "@react-icons/all-files/ai/AiOutlineUnorderedList";
import { Accordion } from "../components/Accordion/Accordion";

const companies = {
  Europe: [
    {
      country: "gb",
      name: "JewelCast",
      website: "https://www.jewelcast.co.uk/",
    },
    {
      country: "gb",
      name: "AA Fine Castings",
      website: "https://www.aafinecasting.com/",
    },
    {
      country: "gb",
      name: "West 1 Casting ltd.",
      website: "https://www.west1castings.co.uk/",
    },
    {
      country: "gb",
      name: "Jewellery Casting Scotland",
      website: "https://www.jewellerycastingscotland.com/",
    },
    {
      country: "gb",
      name: "The Folklore Foundry",
      website: "https://www.folklorefoundry.co.uk/",
    },
    {
      country: "gb",
      name: "ABT Design Ltd",
      website: "www.abtdesign.co.uk",
    },
    {
      country: "gb",
      name: "Mr Beaujangles",
      instagram: "mrbeaujangles",
    },
    {
      country: "gb",
      name: "Merrell Casting",
      website: "https://merrellcasting.co.uk/",
    },
    {
      country: "gb",
      name: "Black Arts Casting",
      website: "https://blackartscasting.co.uk/",
    },
    {
      country: "at",
      name: "Ögussa",
      website: "https://www.oegussa.at/de/firmenkunden/schmuckmetalle/lohnguss",
    },
    {
      country: "at",
      name: "Sturm KG",
      location:
        "https://www.google.com/maps/place/Sturm+KG/@48.3413305,14.1643863,15z/data=!4m5!3m4!1s0x0:0x327a59a0ac92cc50!8m2!3d48.3413305!4d14.1643863",
    },
    {
      country: "ie",
      name: "Colbar Casting Company",
      directory: "https://www.yourlocal.ie/company/379251006312448",
    },
    {
      country: "ie",
      name: "Performance Prototypes",
      website: "http://performanceprototypes.ie/",
    },
    {
      country: "ie",
      name: "Castle Casting ltd.",
      website: "https://www.jewellerymoldmaking.com/",
    },
    {
      country: "no",
      name: "Edelmetallstøperiet",
      website: "http://www.edelmetallstoperiet.no/",
    },
    {
      country: "it",
      name: "Duel",
      website: "https://www.duelvalenza.it/it/",
    },
    {
      country: "it",
      name: "Laboratorio Gottardello",
      website: "https://laboratoriogottardello.com/",
    },
    {
      country: "it",
      name: "L'Orafo Di Gatti Piergiorgio",
      location:
        "https://www.google.com/maps/place/L'Orafo+Di+Gatti+Piergiorgio/@45.6752659,9.6585479,17z/data=!3m1!4b1!4m6!3m5!1s0x478151bb25ae48a5:0x8c66e09a5cd087f1!8m2!3d45.6752622!4d9.6611228!16s%2Fg%2F1tlw7yd8?entry=ttu",
      address: "Via S. Bernardino, 138, 24126 Bergamo BG, Italy",
    },
    {
      country: "de",
      name: "Jewels by ADFK",
      website: "https://www.adfk.eu/en/",
    },
    {
      country: "de",
      name: "HSC Wax",
      website: "https://hscwax.com/",
    },
    {
      country: "ch",
      name: "Altmann Casting",
      website: "https://www.altmann-casting.ch/",
    },
    {
      country: "nl",
      name: "G.J. van den Bergh Zilverwerken",
      website: "https://www.gjvandenberghzilverwerken.nl/",
    },
    {
      country: "nl",
      name: "Jac de Vaal",
      website: "https://www.devaal.nu/",
    },
    {
      country: "hu",
      name: "Gold Rose Bt",
      website:
        "https://www.ekszerkereskedo.hu/szolgaltatok/otvos-goldrose.html",
    },
    {
      country: "be",
      name: "Rayjo",
      website: "https://rayjo.be/",
    },
    {
      country: "be",
      name: "Van Ranst",
      website: "https://www.vanranst.be/",
    },
  ],
  Asia: [
    {
      country: "tw",
      name: "全亞銀鈦鑄造有限公司",
      location:
        "https://www.google.com/maps/place/%E5%85%A8%E4%BA%9E%E9%8A%80%E9%88%A6%E9%91%84%E9%80%A0%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@25.0068343,121.487047,17z/data=!3m1!4b1!4m5!3m4!1s0x3442a82b62bddbad:0xade901edf90141c7!8m2!3d25.0068343!4d121.4892357?hl=en-us",
      address: "No. 15, Lide St, Zhonghe District, Taiwan 235",
    },
    {
      country: "th",
      name: "Precision Casting Co",
      website: "http://www.pcscasting.com/",
    },
  ],
  Oceania: [
    {
      country: "au",
      name: "Lenrose & Ace David Jewellery",
      website: "https://www.lenrose.com/",
    },
    {
      country: "au",
      name: "Peter W Beck",
      website: "http://www.pwbeck.com.au/preciousmetalservices/home",
    },
    {
      country: "au",
      name: "LTH",
      website: "http://lthcad.com.au/moulding",
    },
    {
      country: "au",
      name: "Apecs Investment Castings",
      website: "https://www.apecs.com.au/",
    },
    {
      country: "nz",
      name: "Regal Casting ltd.",
      website: "https://www.regal.co.nz/",
    },
  ],
  ["North America"]: [
    {
      country: "us",
      area: "(Rhode Island)",
      name: "Harrison Casting Co Inc",
      location:
        "https://www.google.com/maps/place/Harrison+Casting+Co+Inc/@41.794947,-71.487776,15z/data=!4m5!3m4!1s0x0:0x3a41bf1890b9db0!8m2!3d41.7949412!4d-71.4876565?hl=en-us",
    },
    {
      country: "us",
      area: "(New York)",
      name: "Carrera Casting",
      website: "https://www.carreracasting.com/",
    },
    {
      country: "us",
      area: "(New York)",
      name: "Daniel Jewelry Casting",
      website: "http://www.danielcasting.com/index.php",
    },
    {
      country: "us",
      area: "(New York)",
      name: "Overnight Mountings",
      website:
        "https://www.overnightmountings.com/services/casting-services.html",
    },
    {
      country: "us",
      area: "(Arkansas)",
      name: "Arkansas Jewelry Studio",
      website: "https://elhavens.com/",
    },
    {
      country: "us",
      area: "(New Mexico)",
      name: "Silver Cloud Casting",
      website: "https://silvercloudcasting.com/",
    },
    {
      country: "us",
      area: "(Texas)",
      name: "The Jeweler's Saw",
      website: "https://thejewelerssaw.org/",
    },
    {
      country: "us",
      area: "(North Carolina)",
      name: "Christopher Ryan Designs Inc.",
      website: "https://designprintcast.com/",
    },
    {
      country: "us",
      area: "(Massachusetts)",
      name: "ZP Casting",
      website: "https://www.zpcasting.com/",
    },
    {
      country: "ca",
      name: "Ready Mounts Inc",
      website: "https://readymounts.com/",
    },
    {
      country: "ca",
      name: "Triocast",
      website: "https://www.triocast.com/",
    },
    {
      country: "ca",
      name: "Clear Mind casting",
      website: "https://clearmindcasting.com/",
    },
    {
      country: "ca",
      name: "Alloyco",
      website: "http://www.alloyco.ca/",
    },
    {
      country: "ca",
      name: "Expert Casting",
      website: "http://expertcasting.ca/",
    },
  ],

  Africa: [
    {
      country: "za",
      name: "Wild Hedge Studios",
      instagram: "wildhedgestudios_services",
    },
    {
      country: "za",
      name: "Cape Precious Metals",
      website: "http://www.capepreciousmetals.com/",
    },
  ],
};

const CompanyListItem = ({ company }) => (
  <li className="bg-white" key={company.name}>
    <div className="px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <ReactCountryFlag
          countryCode={company.country}
          svg
          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
          cdnSuffix="svg"
          style={{
            fontSize: "2em",
            lineHeight: "2em",
          }}
        />
      </div>
      <div className="flex-1 min-w-0">
        {company.area && company.area}
        {company.website ? (
          <a
            href={company.website}
            className="focus:outline-none"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="text-sm font-medium text-gray-900">{company.name}</p>
          </a>
        ) : (
          <div>
            <p className="text-sm font-medium text-gray-900">{company.name}</p>
            <p className="text-sm text-gray-500 truncate">(No website)</p>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {company.location && (
          <div className="flex-1 inline-flex items-center">
            <a
              href={company.location}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <IoMapSharp size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>(Google Maps)</p>
                </div>
              </div>
            </a>
          </div>
        )}
        {company.website && (
          <div className="flex-1 inline-flex items-center">
            <a href={company.website} target="_blank" rel="noreferrer noopener">
              <div className="flex">
                <div className="flex-shrink-0">
                  <FiExternalLink size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Website</p>
                </div>
              </div>
            </a>
          </div>
        )}
        {company.instagram && (
          <div>
            <a
              href={`https://instagram.com/${company.instagram}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <SiInstagram size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Instagram</p>
                </div>
              </div>
            </a>
          </div>
        )}
        {company.directory && (
          <div>
            <a
              href={company.directory}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-o">
                  <AiOutlineUnorderedList size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Directory Page</p>
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  </li>
);

const CastingCompanies = () => {
  return (
    <Layout full>
      <div className="relative pt-16 pb-6 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <PageTitle title="Casting Companies" subtitle="" />
        </div>
      </div>
      <div className="bg-blue-200  md:mt-4">
        <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
          <p className="text-xl text-gray-700 tracking-wide text-center whitespace-pre-wrap mb-4">
            Looking for a casting company near you?
          </p>
          <p className="text-xl text-gray-700 tracking-wide text-left whitespace-pre-wrap">
            Here’s a list of casting companies from around the world. I’ve
            personally used a few of these and the others have been recommended
            to me. I want to keep growing this list, so please recommend your
            casting company, or yourself! Email me the details{" "}
            <a href="mailto:info@waxcarvers.com">info@waxcarvers.com</a> and I
            can add it to this list!
          </p>
          <p className="text-xl mt-4 text-gray-700 tracking-wide text-left whitespace-pre-wrap">
            Where possible I’ve included the website of the company. But some of
            these places are very old school. No website or social media! You
            have to make do with just an address and hope for the best when you
            show up!
          </p>
        </div>
      </div>

      <div className="max-w-prose overflow-auto mx-auto mt-12">
        <nav className="h-full overflow-y-auto" aria-label="Directory">
          {Object.entries(companies).map(([continent, comps]) => (
            <Accordion key={continent} content={{ title: continent }}>
              <ul role="list" className="relative z-0 divide-y divide-gray-200">
                {comps.map((company) => (
                  <CompanyListItem company={company} key={company.name} />
                ))}
              </ul>
            </Accordion>
          ))}
        </nav>
      </div>

      <div className="bg-blue-600 md:mt-4">
        <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
          <p className="text-xl text-white tracking-wide text-left whitespace-pre-wrap">
            No company listed near you? Just google it! That’s how I’ve been
            able to find casting companies in new places. Look for ‘casting
            company (city you’re in)’. You might want to add jewellery or
            precious metal to your search, otherwise you end up with a lot of
            modelling agencies.
          </p>
          <p className="text-xl mt-4 text-white tracking-wide text-left whitespace-pre-wrap">
            You WILL find something if you take some time to look for it.
          </p>
          <p className="text-xl mt-4 text-white tracking-wide text-left whitespace-pre-wrap">
            And email me when you do so I can add it to this list!{" "}
            <a href="mailto:info@waxcarvers.com">info@waxcarvers.com</a>
          </p>
        </div>
      </div>

      <div className="mt-24">
        <JoinNewsLetterButton />
      </div>
      <ReactTooltip />
    </Layout>
  );
};

export default CastingCompanies;
